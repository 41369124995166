import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { theme } from '../../../utils/theme';
import { NewAppText } from '../../ui';
import scrollTo from 'gatsby-plugin-smoothscroll';

const variants = {
  open: {
    // height: '100vh',
    // delayChildren: 0.2,
    transition: { delay: 1, staggerChildren: 0.07, delayChildren: 0.1, staggerDirection: -1 },
  },
  closed: {
    // height: '0px',
    transition: { delay: 1, staggerChildren: 0.05, staggerDirection: 1 },
  },
};

const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface IsOpenProp {
  isOpen: boolean;
  toggleOpen: () => void;
  invert: boolean;
}

export const Navigation: React.FC<IsOpenProp> = ({ isOpen, toggleOpen, invert }) => (
  <MotionUl
    style={{ height: isOpen ? '100vh' : '0px', overflow: "hidden" }}
    variants={variants}
    initial={true}
    // onClick={() => toggleOpen()}
    tappable={isOpen}
  >
    {/* {itemIds.map((i) => (
      <MenuItem i={i} key={i} />
    ))} */}
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledLink invert={invert} to="/work/">
        Work
      </StyledLink>
    </MotionLi>
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledLink invert={invert} to="/approach/">
        Approach
      </StyledLink>
    </MotionLi>
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledLink invert={invert} to="/about/">
        About
      </StyledLink>
    </MotionLi>
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledOutsideLink invert={invert} href="http://careers.rapptrlabs.com/" target="_blank" rel="noreferrer">
        Jobs
      </StyledOutsideLink>
    </MotionLi>
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledLink invert={invert} to="/academy/">
        Academy
      </StyledLink>
    </MotionLi>
    <MotionLi variants={variantsLi} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <StyledLink invert={invert} to="/contact-us">
        Contact Us
      </StyledLink>
    </MotionLi>
  </MotionUl>
);

interface InvertProp {
  invert: boolean;
}

const StyledLink = styled(Link)<InvertProp>`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${theme.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const StyledOutsideLink = styled.a<InvertProp>`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${theme.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const StyledText = styled(NewAppText)<InvertProp>`
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${theme.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const MotionUl = styled(motion.ul)<{ tappable: boolean }>`
  margin: 0;
  padding: 0;
  padding: 25px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: height 0.5s linear;
  animation-delay: 3s;
  pointer-events: ${props => props.tappable ? "auto" : "none"};
`;

const MotionLi = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
