import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BurgerIcon from './BurgerIcon';

import rapptrLogo from '../../images/RapptrLogoWhite.png';
import rapptrLogoBlack from '../../images/RapptrLogoReverse.svg';
import { theme } from '../../utils/theme';
import { desktopSize, laptopSize, mobileSize } from '../../utils';
import { NewAppButton } from '../ui';
import { MobileNav } from './nav/MobileNav';
import { motion } from 'framer-motion';
import scrollTo from 'gatsby-plugin-smoothscroll';

interface NavBarProps {
  // initiallyBlack?: boolean;
  alwaysOpaque?: boolean;
  invert?: boolean;
}

const NavBar: FunctionComponent<NavBarProps> = ({
  // initiallyBlack = false,
  alwaysOpaque = false,
  invert = false,
  location,
}) => {
  const [defaultState, setDefaultState] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);

  const [scrollDir, setScrollDir] = useState('scrolling down');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);
    console.log(scrollDir);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  useEffect(() => {
    if (scrollValue < -50) {
      setToChangedState();
    } else {
      setToDefaultState();
    }
  }, [scrollValue]);

  const handleScroll = () => {
    setScrollValue(document.body.getBoundingClientRect().top);
  };

  const setToDefaultState = () => {
    setDefaultState(true);
  };

  const setToChangedState = () => {
    setDefaultState(false);
  };

  const showBurgerNav = () => {
    setBurgerNav(!burgerNav);
  };

  return (
    <NavBarContainer
      defaultState={defaultState}
      invert={invert}
      alwaysOpaque={alwaysOpaque ?? false}
      scrollingDown={scrollDir === 'scrolling down' && scrollValue < -500}
    >
      <NavBarWrapper>
        <NavBarIconContainer>
          <div style={{ width: '50px' }} />
          <Link to="/">
            <img src={invert ? rapptrLogoBlack : rapptrLogo} alt="Rapptr Labs" height="56px" />
          </Link>
        </NavBarIconContainer>
        <MobileIcon>
          <Link to="/" style={{ zIndex: 1 }}>
            <img src={invert ? rapptrLogoBlack : rapptrLogo} alt="Rapptr Labs" height="32px" />
          </Link>
          {/* <RelDiv> */}
          <MobileNav invert={invert} />
          {/* </RelDiv> */}
        </MobileIcon>
        {/* <SideNavDiv showNav={burgerNav} invert={invert} variants={sidebar}>
          <MobileNavigationContainer invert={invert}>
            <motion.ul variants={variants}>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/companies/" invert={invert}>
                  Work
                </StyledLink>
              </motion.li>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/team/" invert={invert}>
                  Approach
                </StyledLink>
              </motion.li>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/research/" invert={invert}>
                  About
                </StyledLink>
              </motion.li>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/content/" invert={invert}>
                  Jobs
                </StyledLink>
              </motion.li>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/content/" invert={invert}>
                  Academy
                </StyledLink>
              </motion.li>
              <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <StyledLink to="/content/" invert={invert}>
                  Contact Us
                </StyledLink>
              </motion.li>
            </motion.ul>
          </MobileNavigationContainer>
        </SideNavDiv> */}
        <NavBarOptions>
          <SpaceBetweenDiv>
            <NavList>
              {/* <NavListItem>
                <StyledLink to="/portfolio/" activeClassName="active" invert={invert}>
                  Work
                </StyledLink>
              </NavListItem>
              <NavListItem>
                <StyledLink to="/team/" activeClassName="active" invert={invert}>
                  Approach
                </StyledLink>
              </NavListItem>
              <NavListItem>
                <StyledLink to="/research/" activeClassName="active" invert={invert}>
                  About
                </StyledLink>
              </NavListItem> */}

              <NavListItem>
                <StyledLink to="/work/" activeClassName="active" invert={invert}>
                  Work
                </StyledLink>
              </NavListItem>

              <NavListItem>
                <StyledLink to="/approach/" activeClassName="active" invert={invert}>
                  Approach
                </StyledLink>
              </NavListItem>

              <NavListItem>
                <StyledLink to="/about/" activeClassName="active" invert={invert}>
                  About
                </StyledLink>
              </NavListItem>

              <NavListItem>
                <StyledOutsideLink
                  href="http://careers.rapptrlabs.com/"
                  // activeClassName="active"
                  invert={invert}
                  target="_blank"
                  rel="noreferrer"
                >
                  Jobs
                </StyledOutsideLink>
              </NavListItem>
              <NavListItem>
                <StyledLink to="/academy/" activeClassName="active" invert={invert}>
                  Academy
                </StyledLink>
              </NavListItem>

              {/* <NavListItem>
                <StyledLink to="/contact-us/" activeClassName="active" invert={invert}>
                  Contact Us
                </StyledLink>
              </NavListItem> */}

              <NavListItem>
                {/* {location?.href === '/' ? (
                  <ContactUsButton onClick={() => scrollTo('#contact-us')} invert={invert}>
                    Contact Us
                  </ContactUsButton>
                ) : ( */}
                <Link to="/contact-us/">
                  <ContactUsButton invert={invert}>Let's Get Started 🚀</ContactUsButton>
                </Link>
                {/* </StyledLink> */}
              </NavListItem>
            </NavList>
          </SpaceBetweenDiv>
        </NavBarOptions>
        {/* <MobileBurger> */}
        {/* <BurgerIcon showNav={showBurgerNav} showNavState={burgerNav} invert={invert} /> */}
        {/* <MenuToggle toggle={showBurgerNav} /> */}
        {/* </MobileBurger> */}
      </NavBarWrapper>
    </NavBarContainer>
  );
};

interface InvertProp {
  invert?: boolean;
}

const ContactUsButton = styled(NewAppButton)<InvertProp>`
  color: ${(props) => (props.invert ? theme.WHITE_COLOR : theme.MAIN_DARK_BLUE)};
  border-color: ${(props) => (props.invert ? theme.MAIN_DARK_BLUE : theme.WHITE_COLOR)};
  background-color: ${(props) => (props.invert ? theme.MAIN_DARK_BLUE : theme.WHITE_COLOR)};
  font-family: ${theme.SECONDARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  width: 190px;
  height: 40px;
  /* vertical-align: bottom; */
  padding-top: 4px;
  border-radius: 10px;
`;

const RelDiv = styled.div`
  position: relative;
  align-self: center;
`;

const SpaceBetweenDiv = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding-left: 70px; */
  margin-left: auto;
  @media ${desktopSize} {
    /* padding-left: 50px; */
  }
  @media ${laptopSize} {
    padding-left: 0px;
  }
`;

interface ContainerState {
  defaultState: boolean;
  invert: boolean;
  alwaysOpaque: boolean;
  scrollingDown: boolean;
}

const NavBarContainer = styled.div<ContainerState>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: ${(props) => (props.scrollingDown ? (props.defaultState ? '-120px' : '-80px') : '0px')};
  left: 0;
  right: 0;
  margin: 0px;
  padding: 0px;
  z-index: 9999;
  height: ${(props) => (props.defaultState ? '120px' : '80px')};
  background-color: ${(props) =>
    props.defaultState && !props.alwaysOpaque
      ? 'transparent'
      : props.invert
      ? theme.WHITE_COLOR
      : theme.BLACK_COLOR};
  transition: background-color 500ms, height 500ms, padding-top 0.25s, padding-bottom 0.25s,
    top 0.25s ease-in-out;
  @media ${laptopSize} {
    height: ${(props) => (props.defaultState ? '80px' : '55px')};
    top: ${(props) => (props.scrollingDown ? (props.defaultState ? '-80px' : '-55px') : 0)};
  }
  :before {
    content: '';
    position: fixed;
    top: -200px;
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    background-color: ${theme.BLACK_COLOR};
  }
`;

const NavBarWrapper = styled.div`
  position: relative;
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NavBarIconContainer = styled.div`
  /* width: calc(25% - 38px); */
  margin-left: 30px;
  /* margin-top: 10px; */
  float: left;
  @media ${laptopSize} {
    display: none;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media ${laptopSize} {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10000;
    width: 100%;
    text-align: center;
  }
`;

const MobileBurger = styled.div`
  display: none;
  @media ${laptopSize} {
    display: block;
    position: absolute;
    right: 100px;
    top: 0px;
    z-index: 10000;
    height: 40px;
    width: 40px;
    /* background-color: white; */
  }
`;

const NavBarOptions = styled.div`
  width: auto;
  float: right;
  margin-left: 30px;
  margin-top: 2px;
  text-align: right;
`;

const NavList = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  width: max-content;
  @media ${laptopSize} {
    display: none;
  }
`;

interface InvertProp {
  invert?: boolean;
}

const NavListItem = styled.div<InvertProp>`
  /* in case of more overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-right: 25px; */
  position: relative;
  overflow: visible;
  margin: auto 0px auto 25px;
  color: ${(props) => (props.invert ? theme.MAIN_DARK_BLUE : theme.WHITE_COLOR)};
  .active.active {
    color: ${theme.SKY_BLUE};
    /* &:before {
      content: '.';
      font-size: 30px;
      display: inline-block;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: center;
      width: 100%;
      color: ${theme.SKY_BLUE};
    } */
  }
`;

interface LinkProps {
  invert?: boolean;
  makeblue?: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  color: ${(props) =>
    props.invert ? (props.makeblue ? theme.SKY_BLUE : theme.MAIN_DARK_BLUE) : theme.WHITE_COLOR};
  text-decoration: none;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
`;

const StyledOutsideLink = styled.a<LinkProps>`
  font-family: ${theme.SECONDARY_FONT};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  transition: color 0.25s, opacity 0.25s;
  color: ${(props) =>
    props.invert ? (props.makeblue ? theme.SKY_BLUE : theme.MAIN_DARK_BLUE) : theme.WHITE_COLOR};
  text-decoration: none;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
`;

interface SideNavProps {
  showNav: boolean;
  invert: boolean;
}

const SideNavDiv = styled(motion.div)<SideNavProps>`
  display: none;
  @media ${laptopSize} {
    display: ${(props) => (props.showNav ? 'flex' : 'none')};
    height: 100vh;
    width: 101%;
    opacity: ${(props) => (props.showNav ? 1 : 0)};
    position: absolute;
    z-index: ${(props) => (props.showNav ? 998 : -1)};
    left: -1px;
    top: -1px;
    overflow-x: hidden;
    transition: 200ms;
    background-color: ${(props) => (props.invert ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
    padding-top: 50px;
    a {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 33.75px;
      margin-top: 20px;
    }
  }
`;

const MobileNavigationContainer = styled.div<InvertProp>`
  /* margin-top: 100px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.invert ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
`;

export { NavBar };
