import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { theme } from '../../../utils/theme';

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    // stroke={props.invert ? 'hsl(0, 0%, 0%)' : 'hsl(0, 0%, 100%)'}
    stroke={props.invert ? theme.MAIN_DARK_BLUE : theme.SKY_BLUE}
    // stroke={theme.SKY_BLUE}
    strokeLinecap="round"
    {...props}
  />
);

interface ToggleProps {
  toggle: () => void;
  invert: boolean;
}

export const MenuToggle: React.FC<ToggleProps> = ({ toggle, invert }) => (
  <ToggleButton onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
        invert={invert}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
        invert={invert}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
        invert={invert}
      />
    </svg>
  </ToggleButton>
);

const ToggleButton = styled(motion.button)`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 30px;
  width: 50px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
`;
