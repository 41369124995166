import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import hexagonBG from '../../images/hexagons2.svg';
import rapptrLogo from '../../images/RapptrLogoWhite.png';
import socialsBackground from '../../images/SocialsBackground.png';
import linkedInLogo from '../../images/linkedin.png';
import facebookLogo from '../../images/facebook.png';
import twitterLogo from '../../images/twitter.png';
import glassdoorLogo from '../../images/glassdoor.png';
import { NewAppText } from '../ui';
import { NewAppButton } from '../ui/NewAppButton';
import moment from 'moment';
import { OFFICE_ADDRESS, OFFICE_STATE_ZIP, laptopSize } from '../../utils/variables';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, navigate } from 'gatsby';

const DarkFooter = () => {
  return (
    <BlackBackgroundDiv>
      <SegmentsGrid>
        <Segment>
          <LogoImg src={rapptrLogo} alt="Rapptr Labs Logo" onClick={() => navigate('/')} />
        </Segment>
        <Segment>
          <SegmentTitle>
            <SegmentLink to="/work">Work</SegmentLink>
          </SegmentTitle>
          <SegmentTitle>
            <SegmentLink to="/approach">Approach</SegmentLink>
          </SegmentTitle>
          <SegmentTitle>
            <SegmentLink to="/about">About</SegmentLink>
          </SegmentTitle>
          <SegmentTitle>
            <SegmentLink to="/about#our-team">Our Team</SegmentLink>
          </SegmentTitle>
          <SegmentTitle>
            <SegmentLinkA href="http://careers.rapptrlabs.com/">Jobs</SegmentLinkA>
          </SegmentTitle>
          <SegmentTitle>
            <SegmentLink to="/academy/">Academy</SegmentLink>
          </SegmentTitle>
        </Segment>
        <Segment>
          <SegmentTitle>Services</SegmentTitle>
          <SegmentTextLink to="/app-design">App Design</SegmentTextLink>
          {/* Uses "a" tag to load the clutch widget */}
          <SegmentTextAnchor href="/ios/">iOS App Development</SegmentTextAnchor>
          <SegmentTextAnchor href="/android/">Android App Development</SegmentTextAnchor>
          <SegmentTextAnchor href="/">Web Development</SegmentTextAnchor>
          <SegmentTextAnchor href="/">Flutter Hybrid Development</SegmentTextAnchor>
          <SegmentTextAnchor href="/">React Native Hybrid Development</SegmentTextAnchor>
          <SegmentTextAnchor href="/">Backend/API Development</SegmentTextAnchor>
          {/* <SegmentText>Web App Development</SegmentText>
          <SegmentText>Flutter Hybrid Development</SegmentText>
          <SegmentText>React Native Hybrid Development</SegmentText>
          <SegmentText>Backend/API Development</SegmentText>
          <SegmentText>App Prototyping</SegmentText> */}
        </Segment>
        <Segment>
          <SegmentTitle style={{ color: theme.SKY_BLUE }}>Rapptr Labs</SegmentTitle>

          <AddressText>{OFFICE_ADDRESS}</AddressText>
          <AddressText>{OFFICE_STATE_ZIP}</AddressText>
          <AddressText>(212) 913 9626</AddressText>
          <FlippingDiv>
            <ContactUsButton onClick={() => navigate('/contact-us')}>Contact Us</ContactUsButton>
            <SocialsContainer>
              <SocialLink href="https://www.linkedin.com/company/rapptr" target="_blank">
                <SocialsDiv>
                  <img src={linkedInLogo} alt="LinkedIn" />
                </SocialsDiv>
              </SocialLink>
              <SocialLink href="https://www.facebook.com/rapptrlabs" target="_blank">
                <SocialsDiv>
                  <img src={facebookLogo} alt="Facebook" />
                </SocialsDiv>
              </SocialLink>
              <SocialLink href="https://twitter.com/rapptrlabs" target="_blank">
                <SocialsDiv>
                  <img src={twitterLogo} alt="Twitter" />
                </SocialsDiv>
              </SocialLink>
              <SocialLink
                href="https://www.glassdoor.com/Overview/Working-at-Rapptr-Labs-EI_IE2338555.11,22.htm"
                target="_blank"
              >
                <SocialsDiv>
                  <img style={{ width: '14px' }} src={glassdoorLogo} alt="Glassdoor" />
                </SocialsDiv>
                {/* <img
                  style={{ height: '26px', width: '26px', marginTop: '-6px' }}
                  alt="Find us on Glassdoor."
                  src="https://www.glassdoor.com/pc-app/static/img/partnerCenter/badges/eng_CIRCLE_64x64.png"
                /> */}
              </SocialLink>
            </SocialsContainer>
          </FlippingDiv>
        </Segment>
      </SegmentsGrid>
      <FooterText>
        © {moment().format('YYYY')} Rapptr Labs. All Rights Reserved |{' '}
        <SegmentLink2 to="/privacy/">Privacy Policy</SegmentLink2> |{' '}
        <SegmentLink2 to="/tos/">Terms of Service</SegmentLink2>
      </FooterText>

      {typeof window !== 'undefined' && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<script src="https://js.chilipiper.com/marketing.js" type="text/javascript"></script>
            <script>
            const cpTenantDomain = 'rapptrlabs';
            const cpRouterName = 'inbound_router-contact-us-page';
            window.addEventListener("message", ({data}) => {
              if (data.type === "hsFormCallback" && data.eventName === "onFormSubmit") {
                  const lead = data.data.reduce((obj, {name, value}) => Object.assign(obj, { [name]: value }), {});
                  ChiliPiper.submit(cpTenantDomain, cpRouterName, {
                    map: true,
                    lead
                  });
              }
            });
            </script>`,
          }}
        />
      )}
    </BlackBackgroundDiv>
  );
};

const SocialLink = styled.a``;

const LogoImg = styled.img`
  width: 114px;
  margin: 0px;
  cursor: pointer;
  @media ${laptopSize} {
    width: 256px;
    margin: 0px auto;
  }
`;

const FlippingDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${laptopSize} {
    margin-top: 30px;
    flex-direction: column-reverse;
  }
`;

const Segment = styled.div`
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
`;

const FooterText = styled(NewAppText)`
  font-size: 13px;
  color: ${theme.FOOTER_GRAY};
  margin-top: 20px;
  width: 889px;
  @media ${laptopSize} {
    width: 100%;
    text-align: center;
  }
`;

const ContactUsButton = styled(NewAppButton)`
  margin: 20px 0px;
  @media ${laptopSize} {
    width: 100%;
  }
`;

const SocialsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: fit-content;
  @media ${laptopSize} {
    margin: auto;
    margin-bottom: 10px;
  }
`;

const SocialsDiv = styled.div`
  background: url(${socialsBackground});
  height: fit-content;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 26px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddressText = styled(NewAppText)`
  font-size: 16px;
  margin: 0px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentTitle = styled(NewAppText)`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  margin: 0px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentLink = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  margin: 0px;
  width: 100%;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  transition: color 0.15s ease-out;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentLink2 = styled(SegmentLink)`
  font-size: 13px;
  color: ${theme.FOOTER_GRAY};
  font-weight: normal;
`;

const SegmentLinkA = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  margin: 0px;
  width: 100%;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  transition: color 0.15s ease-out;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentText = styled(NewAppText)`
  font-size: 14px;
  line-height: 32px;
  margin: 0px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentTextLink = styled(Link)`
  display: block;
  margin: 0px;
  width: max-content;
  font-size: 14px;
  line-height: 32px;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  transition: color 0.15s ease-out;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentTextAnchor = styled.a`
  display: block;
  margin: 0px;
  width: max-content;
  font-size: 14px;
  line-height: 32px;
  color: ${theme.WHITE_COLOR};
  text-decoration: none;
  transition: color 0.15s ease-out;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
  @media ${laptopSize} {
    text-align: center;
  }
`;

const SegmentsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr 2fr 1.5fr;
  width: 889px;
  @media ${laptopSize} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const BlackBackgroundDiv = styled.div`
  /* background: url(${hexagonBG}); */
  background-color: ${theme.BACKGROUND_COLOR};
  height: fit-content;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
`;

export default DarkFooter;
