import * as React from 'react';
import { useRef } from 'react';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { useDimensions } from './use-dimensions';
import { MenuToggle } from './MenuToggle';
import styled from 'styled-components';
import { Navigation } from './Navigation';
import { theme } from '../../../utils/theme';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 100vw 0px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: `circle(10px at 100vw -20px)`,
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

interface ToggleProps {
  // toggle: () => void;
  invert: boolean;
}

export const MobileNav: React.FC<ToggleProps> = ({ invert }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <StylesContainer>
      <MotionNav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
      >
        <MotionBackground variants={sidebar} />
        <Navigation toggleOpen={toggleOpen} isOpen={isOpen} invert={invert} />
        <MenuToggle
          toggle={() => {
            toggleOpen();
          }}
          invert={invert}
        />
      </MotionNav>
    </StylesContainer>
  );
};

const MotionNav = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
`;

const MotionBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background: ${theme.BLACK_COLOR};
`;

const StylesContainer = styled.div`
  /* body {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #0055ff 0%, rgb(0, 153, 255) 100%);
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* nav {
  }

  .background {
  }

  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
  }

  li {
  }

  .icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
  }

  .text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  } */
`;
